<template>
  <section class="faq-section section-b-space">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="table-responsive">
            <table class="table table-hover table-striped">
              <thead>
              <tr class="table-head">
                <th scope="col">Objet</th>
                <th scope="col">Date</th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <template>
                <tr v-for="(item, index) in messengers" :key="index" v-show="setPaginate(index)"
                    v-b-modal.modal-message>
                  <td v-if="item.read_at!=null">
                    {{ item.subject }}
                  </td>
                  <td v-else>
                    <b>{{ item.subject }}</b>
                  </td>
                  <td v-if="item.read_at!=null">
                    {{ new Date(item.created_at) | date }}
                  </td>
                  <td v-else>
                    <b>{{ new Date(item.created_at) | date }}</b>
                  </td>
                  <td>
                    <table>
                      <tr>
                        <td>
                          <i class="ti-eye"  @click="rrMsg(item)" v-b-modal.modal-message></i>
                        </td>
                        <td>|</td>
                        <td>
                          <i class="ti-trash" style="color: red"></i>
                        </td>
                      </tr>
                    </table>

                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <b-modal
          id="modal-message"
          size="lg"
          centered
          title="Message"
          :hide-footer="false"
          :hide-header="true"
          ok-only
          ok-title="Fermer"
          no-close-on-backdrop

      >
        <div class="row" style="padding: 30px" v-if="messageReading!=''">
          <div class="col-lg-12 text-right">
            Date: {{ new Date(messageReading.created_at) | date }}
            <br/><br/>
          </div>
          <!--<div class="col-lg-12">-->
            <!--De: {{ title }}-->
            <!--<br/><br/>-->
          <!--</div>-->
          <!--<div class="col-lg-12">-->
            <!--A: {{ customer.fname }} {{ customer.lname }}, {{ customer.code }}-->
            <!--<br/><br/>-->
          <!--</div>-->
          <div class="col-lg-12">
            Objet: {{ messageReading.subject }}
            <br/><br/><br/>
          </div>
          <div class="col-lg-12">
            <p v-html="messageReading.content"></p>
          </div>
        </div>
      </b-modal>
      <!--      <div class="row">
              <div class="col-sm-5">
                <b-card no-body class="mb-1" v-for="(item, index) in messengers" :key="index" v-show="setPaginate(index)">
                  <div class="col-xl-12">
                    <a href="#" class="m-2" @click="rrMsg(item)">
                      <div>
                        <h6>{{ item.created_at }}</h6>
                        <h3>{{ item.subject }}</h3>
                      </div>
                    </a>
                  </div>
                </b-card>
                <div class="product-pagination border-cls-blog mb-0" v-if="messengers.length > this.paginate">
                  <div class="theme-paggination-block">
                    <div class="row">
                      <div class="col-sm-12">
                        <nav aria-label="Page navigation">
                          <ul class="pagination">
                            <li class="page-item">
                              <a class="page-link" href="javascript:void(0)"
                                 @click="updatePaginate(current-1)">
                          <span aria-hidden="true">
                            <i class="fa fa-chevron-left" aria-hidden="true"></i>
                          </span>
                              </a>
                            </li>
                            <li
                                class="page-item"
                                v-for="(page_index, index) in this.pages"
                                :key="index"
                                :class="{'active': page_index == current}"
                            >
                              <a
                                  class="page-link"
                                  href="javascrip:void(0)"
                                  @click.prevent="updatePaginate(page_index)"
                              >{{ page_index }}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="javascript:void(0)"
                                 @click="updatePaginate(current+1)">
                          <span aria-hidden="true">
                            <i class="fa fa-chevron-right" aria-hidden="true"></i>
                          </span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-7">
                <b-card no-body class="mb-1" style="min-height: 100vh">
                  <div class="col-xl-12">
                    <div>
                      <h3><span>{{ messageReading.subject }}</span>
                        <div class="pH-A7" tabindex="0"
                             style="margin-right: 10px;margin-top: 5px;float: left;width: 30px"></div>
                      </h3>
                      <h6><i class="ti-calendar" aria-hidden="true"
                             style="margin-left: 10px;margin-right: 15px;font-weight: bold"></i> {{
                          messageReading.created_at
                        }}</h6>
                      <br/>
                      <hr/>
                      <br/>
                      <p v-html="messageReading.content"></p>
                    </div>
                  </div>
                </b-card>
              </div>
            </div>-->
    </div>
  </section>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import _ from 'lodash'

export default {
  name: "messages",

  props: {},

  data() {
    return {
      current: 1,
      paginate: 6,
      paginateRange: 3,
      pages: [],
      paginates: "",
      messageReading: ''
    };
  },

  computed: {
    ...mapGetters("message", ["messengers"]),
    ...mapGetters("auth", ["isLogged", "user"]),
    ...mapGetters("setting", [
      "title",
      "addresse",
      "telephone",
      "contact_email",
      "intro"
    ]),
    ...mapGetters({
      customer: "customer/customer",
    }),
  },

  methods: {
    ...mapActions({
      getMessages: "message/fetchMessengers",
      newMessageCount: "message/unReadCount",
      readMsg: "message/markMessageAsRead",
    }),

    rrMsg(threadMessage) {
      this.messageReading = threadMessage
      this.readMsg({userId: this.user.id, thread: threadMessage})
      console.log('reading', this.messageReading)
    },

    truncateMsg(threadMessage) {
      return _.truncate(threadMessage, {
        length: 150
      })
    },

    getPaginate() {
      this.paginates = Math.round(this.messengers.length / this.paginate);
      this.pages = [];
      for (let i = 0; i < this.paginates; i++) {
        this.pages.push(i + 1);
      }
    },
    setPaginate(i) {
      if (this.current === 1) {
        return i < this.paginate;
      } else {
        return (
            i >= this.paginate * (this.current - 1) &&
            i < this.current * this.paginate
        );
      }
    },
    updatePaginate(i) {
      this.current = i;
      let start = 0;
      let end = 0;
      if (this.current < this.paginateRange - 1) {
        start = 1;
        end = start + this.paginateRange - 1;
      } else {
        start = this.current - 1;
        end = this.current + 1;
      }
      if (start < 1) {
        start = 1;
      }
      if (end > this.paginates) {
        end = this.paginates;
      }
      this.pages = [];
      for (let i = start; i <= end; i++) {
        this.pages.push(i);
      }
      return this.pages;
    }
  },

  watch: {
    user: {
      immediate: true,
      handler: function (val) {
        this.getMessages(val.id);
      }
    }
  },

  mounted() {
    this.$store.dispatch("setting/fetchSettings");
    this.getPaginate();
    this.updatePaginate(1);
  }


};
</script>

<style scoped>
.pH-A7 {
  background-image: url(https://www.gstatic.com/images/icons/material/system_gm/1x/label_important_outline_black_20dp.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  height: 20px;
  opacity: .71;
  width: 20px;
}

.ha .a9q {

}
</style>
<style>
.modal-content {
  background: white;
}
</style>
